<script lang="ts">
	import HelpSectionCard from "./HelpSectionCard.svelte";
	import pallets from "../assets/images/pallets.png?webp&h=325&imagetools";
	import crates from "../assets/images/crates.png?webp&h=325&imagetools";
	import storageArea from "../assets/images/storage-area.png?webp&h=325&imagetools";
	import longParts from "../assets/images/long-parts.png?webp&h=325&imagetools";
	import smallGoods from "../assets/images/small-goods.png?webp&h=325&imagetools";
	import otherProblems from "../assets/images/other-problem.png?webp&h=325&imagetools";
	import palletsMobile from "../assets/images/pallets.png?webp&h=271&imagetools";
	import cratesMobile from "../assets/images/crates.png?webp&h=271&imagetools";
	import storageAreaMobile from "../assets/images/storage-area.png?webp&h=271&imagetools";
	import longPartsMobile from "../assets/images/long-parts.png?webp&h=271&imagetools";
	import smallGoodsMobile from "../assets/images/small-goods.png?webp&h=271&imagetools";
	import otherProblemsMobile from "../assets/images/other-problem.png?webp&h=271&imagetools";
	import DecoratedHeadline from "./DecoratedHeadline.svelte";
	import { helpPalletsPathMap } from "../../../core/schema/paths/helpPalletsPathMap.js";
	import { getGlobalSettings } from "../getGlobalSettings.js";
	import { helpExtraAreaPathMap } from "../../../core/schema/paths/helpExtraAreaPathMap.js";
	import { helpLongPartsPathMap } from "../../../core/schema/paths/helpLongPartsPathMap.js";
	import { helpSmallGoodsPathMap } from "../../../core/schema/paths/helpSmallGoodsPathMap.js";
	import { helpCratesPathMap } from "../../../core/schema/paths/helpCratesPathMap.js";
	import { contactPathMap } from "../../../core/schema/paths/contactPathMap.js";
	import { nbspify } from "../../../core/utils/nbspify.js";

	let containerClass = "";
	export { containerClass as class };
	export let startLazyLoadingOnIndex = 0;

	const { TENANT_LOCALE } = getGlobalSettings();

	const sections = [
		{ image: pallets, imageMobile: palletsMobile, urlSlug: helpPalletsPathMap, title: "Chci skladovat palety" },
		{
			image: storageArea,
			imageMobile: storageAreaMobile,
			urlSlug: helpExtraAreaPathMap,
			title: "Chci skladovou plochu navíc",
		},
		{
			image: longParts,
			imageMobile: longPartsMobile,
			urlSlug: helpLongPartsPathMap,
			title: "Chci skladovat dlouhé díly",
		},
		{
			image: smallGoods,
			imageMobile: smallGoodsMobile,
			urlSlug: helpSmallGoodsPathMap,
			title: "Chci skladovat malé zboží",
		},
		{ image: crates, imageMobile: cratesMobile, urlSlug: helpCratesPathMap, title: "Chci skladovat v přepravkách" },
		{
			image: otherProblems,
			imageMobile: otherProblemsMobile,
			urlSlug: contactPathMap,
			title: "Mám jiný specifický problém",
		},
	] as const;
</script>

<div class="container flex w-full flex-col items-center gap-16 {containerClass}">
	<DecoratedHeadline>
		{nbspify("Jak vám")}
		<br />
		{nbspify("můžeme pomoci?")}
	</DecoratedHeadline>

	<div
		class="mx-auto grid w-full grid-cols-2 items-start justify-center justify-items-center gap-4 md:gap-[3.125rem] xl:grid-cols-3"
	>
		{#each sections as { image, imageMobile, urlSlug, title }, index}
			<HelpSectionCard
				urlSlug={urlSlug[TENANT_LOCALE]}
				index={index + 1}
				{image}
				{imageMobile}
				{title}
				loading={index >= startLazyLoadingOnIndex ? "lazy" : "eager"}
			/>
		{/each}
	</div>
</div>
